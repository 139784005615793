import React from "react";
import { Button, Form } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
const Dmt1 = () => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          margin: "10px",
          width: "40vw",
          padding: "10px",
          textAlign: "center",
          border: "2px solid #A9A9A9",
        }}
      >
        <h3> Find or Create Remitter</h3>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Remitter Mobile Number"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Remitter Name"
          />
        </Form.Group>
        <Tabs
          fill
          defaultActiveKey="All-Beneficiary"
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="All-Beneficiary"
            title="All Beneficiary"
            className="test-tab"
          >
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
              />
            </Form.Group>
          </Tab>
          <Tab
            eventKey="Add-Recipient"
            title="Add Recipient"
            className="test-tab"
          ></Tab>
        </Tabs>
      </div>

      <div
        style={{
          margin: "10px",
          width: "40vw",
          padding: "10px",
          textAlign: "center",
          border: "2px solid #A9A9A9",
        }}
      >
        <h3> Transfer To Beneficiary</h3>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary Name"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary A/C"
          />
        </Form.Group>
        <div style={{ display: "flex" }}>
          Total
          <input type="text" />
        </div>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter T-Pin"
          />
        </Form.Group>
        <div style={{ display: "flex",margin:'auto' }}>
          <Button variant="primary"> Transfer Now </Button>
          <Button variant="success"> Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default Dmt1;
