import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  myAccountInfo: null,
  token: null,
};
export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state

  function setToken(token) {
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  }
  function setMyAccountInfo(info) {
    console.log(info);
    dispatch({
      type: "SET_MY_ACCOUNT_INFO",
      payload: info,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        token: state.token,
        myAccountInfo: state.myAccountInfo,
        setToken,
        setMyAccountInfo
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
